<template>
    <b-modal size="xl" v-model="showModal" title="Pregled sporočila">
        <b-overlay :show="show">
            <div v-if="message !== null && message">
                <div class="d-flex justify-content-start">
                    <b-badge class="mr-1" :variant="getPriority(message.priority).variant">
                        <div class="d-flex align-items-center">
                            <feather-icon style="height: 19px; width: 19px; margin-right: 6px;" :icon="getPriority(message.priority).icon"/>
                            <div style="font-size: 14px;line-height: 15px!important; height: 15px!important;">{{ getPriority(message.priority).name }}</div>
                        </div>
                    </b-badge> 
                    <b-badge :variant="getType(message.type).variant">
                        <div class="d-flex align-items-center">
                            <feather-icon style="height: 19px; width: 19px; margin-right: 6px;" :icon="getType(message.type).icon"/>
                            <div style="font-size: 14px;line-height: 15px!important; height: 15px!important;">{{ getType(message.type).name }}</div>
                        </div>  
                    </b-badge> 
                </div>
                <div class="d-flex align-items-center mt-2 justify-content-center"> 
                    <h1 style="">{{ message.title }}</h1>
                </div>
                <div class="ql-snow">
                    <div class="ql-editor" v-html="message.content"/>
                </div>
                <div v-if="message.attachments && attachments.length>0">
                    <div style="font-size: 16px; border-top: 1px dashed gray;">
                        <div class="d-flex justify-content-between" style="font-size: 14px; font-weight: bold; padding-bottom: 10px; padding-top: 10px;">
                            <div>
                                Priponke ({{attachments.length}}):
                            </div>

                            <div>
                                <b-button style="height: 22px; padding: 2px 5px;" @click="downloadAll"><feather-icon icon="DownloadIcon" style="cursor: pointer;"/> Prenesi vse</b-button>
                            </div>
                        </div>
                        <div style="display: flex; flex-wrap: wrap;">
                            <div class="mb-1" v-for="(file, id) in attachments" :key="file.minio_id" @mouseenter="hoveredItem = id" @mouseleave="hoveredItem = -1" >
                                <div style="margin-left: 3px; margin-right: 3px; border: 1px solid #d4d4d4;; position: absolute; z-index: 1100; background: #d9d9d9" v-if="hoveredItem == id">
                                    <div style="height: 8rem; width: 10rem; cursor: pointer; overflow-y: hidden; overflow-x: hidden;">
                                        <div class="d-flex"  @click="openImageFullSize(file)">
                                            <div style="padding: 8px; " class="d-flex justify-content-center pt-1">
                                                <feather-icon :icon="getFileIcon(file)"/>
                                            </div>
                                            <div class="d-flex flex-column align-items-start" style="width:100%; height: 8rem;">
                                                <div class="cutText mb-auto" style="margin-top: 13px;">
                                                    {{ file.name }}
                                                </div>
                                                <div style="width: 100%; padding-bottom: 5px;">
                                                    <div style="font-size: 12px; font-weight: bold; ">
                                                        {{getFileSize(file.byte_size)}}
                                                    </div>
                                                    <b-button style="width: 100%; height: 25px; padding: 0;" @click="getMessageAttachment(file)">
                                                        <div class="d-flex align-items-center justify-content-center">
                                                            <feather-icon icon="DownloadIcon" style="cursor: pointer;"/>
                                                        </div>
                                                    </b-button>
                                                </div>
                                            </div>
                                            <div style="padding: 14px; " class="d-flex justify-content-center pt-1">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="margin-left: 3px; margin-right: 3px; border: 1px solid #d4d4d4;">
                                    <div class="w-100" style="overflow: hidden; height: 6rem; width: 10rem!important;" v-if="file.file_type === 'image/webp'">
                                        <img alt="" style="cursor: pointer; object-fit: cover; width: 100%; height: 100%; " @click="getMessageAttachment(file)" :src="`/api/admin/v1/attachments/message/thumbnail_${file.minio_id}`"/>
                                    </div>
                                    <div v-else-if="file.minio_id.split('.')[1] ==='gif'">
                                        <div style="height: 6rem; width: 10rem; cursor: pointer; overflow-y: hidden;" class="d-flex justify-content-center align-items-center">
                                            <feather-icon icon="ImageIcon" size="50" color="#b7b7b7"/>
                                        </div>
                                    </div>
                                    <div v-else-if="file.extension === 'pdf'">
                                        <div  @click="getMessageAttachment(file)" v-if="attachmentsReady && attachmentsDownloaded[file.minio_id]">
                                            <pdf style="height: 6rem; width: 10rem; cursor: pointer; overflow-y: hidden;" :src="attachmentsDownloaded[file.minio_id].objectUrl"/>
                                        </div>
                                    </div>
                                    <div v-else-if="file.extension !== 'pdf'">
                                        <div style="height: 6rem; width: 10rem; cursor: pointer; overflow-y: hidden;" class="d-flex justify-content-center align-items-center">
                                            <feather-icon icon="FileIcon" size="50" color="#b7b7b7"/>
                                        </div>
                                    </div>

                                    <div style="height: 2rem; background: #e7e7e7" class="d-flex align-items-center">
                                        <div style="padding-left: 5px; padding-right: 5px; margin: 0;">
                                            <feather-icon :icon="getFileIcon(file)" style="cursor: pointer;"/>
                                        </div>
                                        <div class="cutTextOne" style="width: 8rem; margin: 0;">
                                            {{ file.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <div>
                        <div class="textMedium mb-1">
                            Izbrane stranke
                            <!--                          <feather-icon id="clients_link" icon="InfoIcon" style="height: 19px; width: 19px;" />-->
                        </div>
                        <!--                        <b-tooltip placement="right" target="clients_link" title="Stranke" triggers="hover">-->
                        <!--                            Zeleno => prebrano<br>Rdeče => neprebrano-->
                        <!--                        </b-tooltip>-->
                        <span v-for="(client,index) in message.client_ids" :key="index">
                            <b-badge variant="primary" style="margin: 2px;">{{ getClient(client) }}</b-badge>
                        </span>
                    </div>
                </div>
            </div>
        </b-overlay>
        <template #modal-footer>
            <b-button @click="Close()">Zapri</b-button>
        </template>
    </b-modal>
    
</template>
<script>
    import { BModal, BOverlay, BBadge, BButton } from 'bootstrap-vue'
    import pdf from 'vue-pdf'
    import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
    import {typeOptions, priorityOptions} from '@/libs/Messages'

    export default {
        components:{BModal, BOverlay, BBadge, BButton, pdf, FeatherIcon},
        data() {
            return {
                hoveredItem: -1,
                attachmentsReady: false,
                messageId:'',
                message: null,
                attachments:[],
                attachmentsDownloaded: {},
                clients:[],
                show:false,
                showModal: false,
                typeOptions,
                priorityOptions
            }
        },
        methods:{
            Open(val) {
                if (val) {
                    this.messageId = val
                    this.loadData()
                    this.showModal = true
                } else {
                    this.Close()
                }
            },
            Close() {
                this.showModal = false
            },
            async loadMessages() {
                try {
                    const response = await this.$http.get(`/api/admin/v1/message/${this.messageId}`)
                    this.message = response.data.message ?? null
                } catch {
                    this.$printError('Prišlo je do napake!', 3000)
                }
            },
            async loadClients() {
                try {
                    const res = await this.$http.get('/api/admin/v1/client/')
                    this.clients = res.data ?? []
                } catch {
                    this.$printError('Prišlo je do napake!', 3000)
                }
            },
            async loadAttachments() {
                try {
                    if (this.message.attachments.length <= 0) return
                    const response = await this.$http.get(`/api/admin/v1/message/attachments/${this.messageId}`)
                    this.attachments = response.data.message_attachments ?? []
                } catch {
                    this.$printError('Prišlo je do napake!', 3000)
                }
            },
            async getMessageAttachment(doc) {
                try {
                    this.show = true

                    const response = await this.$http.get(`/api/admin/v1/attachments/message/${doc.minio_id}`, { responseType: 'blob' }) 
                    
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')

                    link.href = url
                    link.setAttribute('download', `${doc.name}.${doc.extension}`) //or any other extension

                    document.body.appendChild(link)
                    link.click()
                    
                } catch {
                    this.$printError('Prišlo je do napake!', 3000)
                } finally {
                    this.show = false
                }
            },
            async downloadTogether() {
                this.attachmentsReady = false
                const array = []
                const arrayId = []
                for (const item of this.attachments) {
                    if (item.extension !== 'pdf') {
                        continue
                    }

                    const single_pdf = this.$http.get(`/api/admin/v1/attachments/message/${item.minio_id}`, {responseType: 'blob'})

                    array.push(single_pdf)
                    arrayId.push(item.minio_id)
                }

                const responses = await Promise.all(array)
                const tmpArray = {}

                for (const id in responses) {
                    const item = responses[id]

                    const blob = new Blob([item.data])
                    const objectUrl = URL.createObjectURL(blob)
                    const pdf_src = await pdf.createLoadingTask(objectUrl)

                    if (item && item.data && item.data.type === 'application/pdf') {
                        tmpArray[arrayId[id]] = {src: pdf_src, objectUrl}
                    }
                }


                this.attachmentsDownloaded = tmpArray
                this.attachmentsReady = true
            },
            async downloadAll() {
                try {
                    this.show = true

                    const response = await this.$http.get(`/api/admin/v1/attachments/bulk/message/${this.messageId}`, { responseType: 'blob' })

                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')

                    link.href = url
                    link.setAttribute('download', `${this.message.title}_priponke.zip`)

                    document.body.appendChild(link)
                    link.click()

                } catch {
                    this.$printError('Prišlo je do napake!', 3000)
                } finally {
                    this.show = false
                }
            },
            async loadData() {
                this.show = true
                await this.loadMessages()
                if (this.message.attachments) {
                    await this.loadAttachments()
                }

                await this.loadClients()

                if (this.attachments) {
                    await this.downloadTogether()
                }

                this.show = false
            },
            getClient(id) {
                const client = this.clients.find(client => client.id === id)
                if (client) {
                    return client.alias
                } else {
                    return ''
                }
            },
            getVariant(client) {
                if (client.read) {
                    if (client.read) return 'success'
                }
                return 'danger'
            },
            getPriority(val) {
                return this.priorityOptions.find(priority => priority.value === val)
            },
            getType(val) {
                return this.typeOptions.find(type => type.value === val)
            },
            getFileIcon(file) {
                try {
                    switch (file.file_type.split('/')[0]) {
                    case 'image':
                        return 'ImageIcon'
                    case 'audio':
                        return 'HeadphonesIcon'   
                    case 'application':
                        if (file.extension === 'pdf') return 'FileTextIcon'
                        else return 'PackageIcon'
                    case 'video':
                        return 'FilmIcon'
                    default:
                        return 'PackageIcon'
                    }
                } catch {
                    this.$printError('Prišlo je do napake!', 3000) 
                }
            
            },
            getFileSize(val) {
                let em = 'B'
                let size = val

                if (size > 1024) {
                    size = size / 1024
                    em = 'KB'
                }

                if (size > 1024) {
                    size = size / 1024
                    em = 'MB'
                }

                if (size > 1024) {
                    size = size / 1024
                    em = 'GB'
                }

                size = Math.round(size * 100) / 100

                return `${size} ${em}`
            },
            openImageFullSize(attachment) {
                if (attachment.file_type && attachment.file_type.split('/')[0] === 'image') {
                    this.$emit('image-large', attachment)
                }
            }
        }
    }
</script>
<style scoped>

  .textMedium{
    font-size: 18px;
    font-weight: bold;
  }

  .cutText{
    margin-top: 5px;
    font-weight: bold;
    font-size: 13px;
    padding-right: 5px;
    overflow: hidden;
    word-wrap: anywhere;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    line-height: 1em;
    height: 3em;
    -webkit-box-orient: vertical;
  }

  .cutTextOne{
    margin-top: 5px;
    font-weight: bold;
    font-size: 13px;
    padding-right: 5px;
    overflow: hidden;
    word-wrap: anywhere;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    line-height: 1em;
    -webkit-box-orient: vertical;
  }
</style>
<style>
@media (min-width: 1200px) {
    [dir="ltr"] .modal-xl {
        margin-left: auto;
        margin-right: auto;
    }
}
</style>