<template>
    <div @drop="checkDrop">
        <quill-editor @change="removeImg" class="quillEditorClass" :style="cssVars" ref="quill" v-model="model" :options="options" :theme="theme" />
    </div>
</template>

<script>
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import { quillEditor } from 'vue-quill-editor'

    export default {
        components: {
            quillEditor
        },
        props: {
            value: {
                default: null
            },
            theme: {
                type: String,
                default: 'snow'
            },
            minHeight: {
                type: String,
                default: '200'
            }
        },
        data() {
            return {
                options: {
                    'theme': 'snow',
                    'placeholder': 'Opis',
                    'modules': {
                        toolbar:{ 
                            'container': [
                                ['bold', 'italic', 'underline', 'strike'], ['blockquote', 'code-block'], [{ header: [1, 2, 3, 4, 5, 6, false] }],   [{ 'script': 'sub'}, { 'script': 'super' }],
                                [{'indent': '-1'}, {'indent': '+1'}], [{'list': 'ordered'}, {'list': 'bullet'}], [{ 'align': [] }, { 'direction': 'rtl' }], [{'background': []}, {'color': []}], ['clean'], ['link']
                            ]
                        }
                    }
                }
            }
        },
        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    value = this.checkMatchImage(value)
                    value = this.checkMatchAudio(value)
                    value = this.checkMatchVideo(value)

                    this.$emit('input', value)
                }
            },
            cssVars() {
                return {
                    '--height': `${this.minHeight  }px`
                }
            }
        },
        methods: {
            removeImg() {
                const images = this.$refs.quill.$el.getElementsByTagName('img')
                for (let i = 0; i < images.length; i++) {
                    images[i].remove()
                }
            },
            checkDrop(e) {
                e.preventDefault()
            },
            checkMatchVideo(value) {
                if (!value) return ''
                if (value.match(/<video .*?>/g)) {
                    this.$printWarning('Vstavljanje videa ni dovoljeno!')
                    return value.replace(/<video .*?>/g, '') 
                }
                return value
            },
            checkMatchAudio(value) {
                if (!value) return ''
                if (value.match(/<audio .*?>/g)) {
                    this.$printWarning('Vstavljanje audia ni dovoljeno!')
                    return value.replace(/<audio .*?>/g, '') 
                }
                return value
            },
            checkMatchImage(value) {
                if (!value) return ''
                if (value.match(/<img .*?>/g)) {
                    this.$printWarning('Vstavljanje slik ni dovoljeno!')
                    return value.replace(/<img .*?>/g, '') 
                }
                return value
            }
        }
    }
</script>
<style lang="scss">

  .quillEditorClass{
    .ql-editor {
      overflow-y: auto;
    }

    .ql-container {
      resize: vertical;
      overflow-y: hidden;
    }
  }


</style>