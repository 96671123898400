<template>
    <b-modal hide-footer size="lg" v-model="show">
        <b-img v-if="image !== ''" center fluid :src="getImage"/>
    </b-modal>
</template>
<script>
    import { BModal, BImg} from 'bootstrap-vue'

    export default {
        components: {BModal, BImg},
        data() {
            return {
                show: false,
                image:''
            }
        },
        methods:{
            Open(val) {
                this.image = JSON.parse(JSON.stringify(val))
                this.show = true
            },
            Close() {
                this.image = ''
                this.show = false
            }
        },
        computed:{
            getImage() {
                return this.image
            }
        }
    }
</script>
<style>
 .modal-lg {
    max-width: 70vw !important;
   }

.modal-dialog {
    max-width: 70vw !important;

  }
</style>