export const typeOptions = [
    {value:'version', icon:'CpuIcon', name:'Verzija', variant: 'secondary'},
    {value:'changelog', icon:'BookIcon', name:'Dnevnik sprememb', variant: 'secondary'},
    {value:'warning', icon:'ShieldIcon', name:'Opozorilo', variant: 'warning'},
    {value:'message', icon:'MailIcon', name:'Sporočilo', variant: 'secondary'},
    {value:'expiration', icon:'TrendingDownIcon', name:'Iztek', variant: 'warning'},
    {value:'finance', icon:'DollarSignIcon', name:'Finance', variant: 'secondary'}
]

export const priorityOptions = [
    {value:'info', icon:'InfoIcon', name:'Info', variant: 'info'},
    {value:'warning', icon:'ShieldIcon', name:'Opozorilo', variant: 'warning'},
    {value:'danger', icon:'AlertTriangleIcon', name:'Nevarnost', variant: 'danger'},
    {value:'succes', icon:'CheckSquareIcon', name:'Uspeh', variant: 'success'}
]